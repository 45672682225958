<template>
  <div class="card mb-4">
    <!-- <div class="card-header pb-0">
      <a
        class="btn bg-gradient-warning mt-4 w-20"
        href="/form-hybrid-transactions"
        type="button"
      ><i class="fas fa-plus"> </i> Wallet Invoice</a
      >
    </div>-->
      <div class="card-header pb-0 p-3">
        <div class="row">
          <div class="col-6 d-flex align-items-center">
            <h6 class="mb-0">Invoices</h6>
          </div>
          <div class="col-6 text-end">
            <router-link :to="{ name: 'Billing', params: {} }" class="btn bg-gradient-warning w-20" >Back</router-link>
            <router-link :to="{ name: 'Wallet Invoice Form', params: {} }" class="btn bg-gradient-success mx-1 w-20" v-if="[1,7].includes($store.getters.currentUser.role)">Add</router-link>
          </div>
        </div>
      </div>
    <div class="card-body px-0 pt-0 pb-2">
      <div class="table-responsive p-3">
        <table class="table align-items-center mb-0">
          <thead>
            <tr>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Code
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
              >
                User
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
              >
                Emission
              </th>
              <th
                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Due Date
              </th>
              <th
                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Status
              </th>
              <th
                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Transaction
              </th>
              

              <th
                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Total
              </th>
              <th
                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Amount
              </th>
              
              <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
          <tr v-for="(item, index) in walletInvoices" :key="index">
            <td>            
              <span class="text-xs">#{{item.invoice_code}}</span>
            </td>
            <td>
              <span class="text-secondary text-xs font-weight-bold">{{item.user_name ? item.user_name : '- - -'}}</span>
            </td>
            <td>
              <span class="text-secondary text-xs font-weight-bold">{{item.emission_date ? formatDate(item.emission_date) : '- - -'}}</span>
            </td>  
            <td class="align-middle text-center">
              <span class="text-secondary text-xs font-weight-bold">{{item.due_date ? formatDate(item.due_date) : '- - -'}}</span>
            </td>         
            <td class="align-middle text-center text-sm">
              <span class="text-secondary text-xs font-weight-bold">{{item.status}}</span>
            </td>
            <td class="align-middle text-center text-sm">
              <span class="text-secondary text-xs font-weight-bold">{{item.wallet_transaction? item.wallet_transaction : '- - -'}}</span>
            </td>


            <td class="align-middle text-center">
              <span class="text-secondary text-xs font-weight-bold">${{item.total}}</span>
            </td>
            <td class="align-middle text-center">
              <span class="text-secondary text-xs font-weight-bold">{{item.amount? (item.amount + ' LCP'):'- - -'}}</span>
            </td>
            <td class="align-middle text-center">

              <a target="_blank" :href="item.payment_link_paypal" class="text-secondary text-xs text-dark font-weight-bold" v-if="item.payment_link_paypal && item.status=='Pending'"><i class="fas fa-link mr-1"/></a>
              <i class="fas fa-link mr-1 text-xs text-secondary" v-else/>
              
              <router-link :to="{ name: 'Wallet Invoice View', params: {'invoice_code':  item.invoice_code} }" class="text-secondary text-xs text-dark font-weight-bold mx-3" ><i class="fas fa-eye "/></router-link>
              
              <i class="fas fa-trash mr-1 text-xs text-dark " @click="handleDelete(item.invoice_code)" style="cursor:pointer" v-if="[1,7].includes($store.getters.currentUser.role) && item.status=='Pending'"/>
              <i class="fas fa-trash mr-1 text-xs text-secondary" v-else/>

            </td>
          </tr>
          </tbody>

        </table>
      </div>
    </div>
  </div>
</template>

<script>
// import SoftAvatar from "@/components/SoftAvatar.vue";
// import SoftBadge from "@/components/SoftBadge.vue";
import img1 from "../../assets/img/team-2.jpg";
import img2 from "../../assets/img/team-3.jpg";
import img3 from "../../assets/img/team-4.jpg";
import img4 from "../../assets/img/team-3.jpg";
import img5 from "../../assets/img/team-2.jpg";
import img6 from "../../assets/img/team-4.jpg";
import axios from 'axios';
import moment from 'moment'

export default {
  name: "hybrid-transaction-token",
  created() {
    this.fetchInvoices();
  },
  data() {
    return {
      img1,
      img2,
      img3,
      img4,
      img5,
      img6,
      tokens: [],
      walletInvoices: [],
    };
  },
  methods: {
    async fetchInvoices() {
      let self = this;
      await axios.get(`${process.env.VUE_APP_API_URl}/api/wallet/invoices`).then(response => {
        console.log('Wallet Invoices', response.data)
        if (response.data.status == 'success') {
          self.walletInvoices = response.data.data
        }          
      })
      .catch(error => {
        console.log(error);
      });
    },
    formatDate(date) {
      return moment(date).format('LL')
    },

    async handleDelete(code) {
      let self = this;
      await axios.delete(`${process.env.VUE_APP_API_URl}/api/wallet/invoices/${code}/delete`).then(response => {
        if (response.data.status == 'success') {
          self.fetchInvoices();
          alert(response.data.message);
        } else {
          alert(response.data.message);
        }          
      }).catch(error => {
        if (error.response.data.message) {
          alert(error.response.data.message);
        } else {
          console.log(error);
        }        
      });
    }
  },
  components: {
    // SoftAvatar,
    // SoftBadge,
  },
};
</script>
