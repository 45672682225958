<template>
  <div class="card h-100 mt-4">
    <div class="card-header pb-0 p-3">
      <div class="row">
        <div class="col-6 d-flex align-items-center">
          <h6 class="mb-0">Invoices</h6>
        </div>
        <div class="col-6 text-end">
          <router-link :to="{ name: 'Wallet Invoices', params: {} }" class="btn btn-outline-success text-xs" >View All</router-link>
        </div>
      </div>
    </div>
    <div class="card-body p-3 pb-0 mb-0">
      <div class="table-responsive p-0">
        <table class="table align-items-center mb-0">
          <thead>
            <tr>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Code
              </th>
               <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
              >
                User
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
              >
                Emission
              </th>
               <th
                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Due Date
              </th>
              <th
                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Status
              </th>
               <th
                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Transaction
              </th> 
              <th
                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Total
              </th>
              <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
          <tr v-for="(item, index) in walletInvoices" :key="index">
            <td>            
              <span class="text-xs">#{{item.invoice_code}}</span>
            </td>
            <td>
              <span class="text-secondary text-xs font-weight-bold">{{item.user_name ? item.user_name : '- - -'}}</span>
            </td>
            <td>
              <span class="text-secondary text-xs font-weight-bold">{{item.emission_date ? formatDate(item.emission_date) : '- - -'}}</span>
            </td>
             <td class="align-middle text-center">
              <span class="text-secondary text-xs font-weight-bold">{{item.due_date ? formatDate(item.due_date) : '- - -'}}</span>
            </td>
            <td class="align-middle text-center text-sm">
              <span class="text-secondary text-xs font-weight-bold">{{item.status}}</span>
            </td>
            <td class="align-middle text-center text-sm">
              <span class="text-secondary text-xs font-weight-bold">{{item.wallet_transaction? item.wallet_transaction : '- - -'}}</span>
            </td>
           

            <td class="align-middle text-center">
              <span class="text-secondary text-xs font-weight-bold">${{item.price}}</span>
            </td>
            <td class="align-middle text-center">
              
              <a target="_blank" :href="item.payment_link_paypal" class="text-secondary text-xs text-dark font-weight-bold" v-if="item.payment_link_paypal"><i class="fas fa-link mr-1"/></a>
              <i class="fas fa-link mr-1 text-xs text-secondary" v-else/>
              <router-link :to="{ name: 'Wallet Invoice View', params: {'invoice_code':  item.invoice_code} }" class="text-secondary text-xs text-dark font-weight-bold mx-3" ><i class="fas fa-eye "/></router-link>
            </td>
          </tr>
          </tbody>

        </table>
      </div>
    </div>
  </div>
</template>

<script>
// import SoftButton from "@/components/SoftButton.vue";
import axios from 'axios';
import moment from 'moment'

export default {
  name: "InvoiceCard",
  components: {
    // SoftButton,
  },
  mounted() {
    this.fetchInvoices();
  },
  data() {
    return {
      walletInvoices: []
    }
  },
  methods: {

    fetchInvoices() {
      let self = this;
        axios.get(`${process.env.VUE_APP_API_URl}/api/wallet/invoices/open`).then(response => {
          console.log('Wallet Invoices', response.data)
          if (response.data.status == 'success') {
            self.walletInvoices = response.data.data
          }          
        })
        .catch(error => {
          console.log(error);
        });
 
    },

    formatDate(date) {
      return moment(date).format('LL')
    }

  }

};
</script>
